import React, { useState } from 'react';
import './EmailPopup.css';
import { IoClose } from 'react-icons/io5';

function EmailPopup({ isOpen, onClose, onSubmit }) {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const formData = new FormData();
      formData.append('EMAIL', email);

      await fetch(
        'https://tiakiai.us21.list-manage.com/subscribe/post?u=2df5fb9380d9d4bf077cfd504&id=f689ed94c8&f_id=003e88e6f0',
        {
          method: 'POST',
          mode: 'no-cors',
          body: formData
        }
      );

      setSubmitStatus('success');
      setEmail('');
      setTimeout(() => {
        onClose();
        setSubmitStatus(null);
      }, 2000);
      
    } catch (error) {
      console.error('Submission error:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="email-popup-overlay">
      <div className="email-popup">
        <button className="close-button" onClick={onClose}>
          <IoClose />
        </button>
        <h2>Join our Waitlist</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="EMAIL"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isSubmitting}
          />
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
          {submitStatus === 'success' && (
            <p className="success-message">Thanks for joining! 🎉</p>
          )}
          {submitStatus === 'error' && (
            <p className="error-message">Something went wrong. Please try again.</p>
          )}
          <p className="spam-notice">We promise not to spam. You can unsubscribe at any time.</p>
        </form>
      </div>
    </div>
  );
}

export default EmailPopup; 