import React, { useState } from 'react';
import './FAQ.css';
import { IoIosArrowDown } from 'react-icons/io';

function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is virtual staging?",
      answer: "Virtual staging is a cost-effective method for real estate agents to make properties more appealing to potential buyers. Rather than using traditional home staging techniques, where furniture and decor is physically brought in and arranged, digital technology is used to add furniture, decor and other design elements to a room. This is done through the use of computer software and 3D rendering, creating a virtual representation of a fully furnished and decorated space.\n\nBy using virtual staging, real estate agents can increase the perceived value of a property and make it more attractive to potential buyers by showing them how the space could look when it is fully furnished and decorated.\n\nAdditionally, virtual staging can help properties stand out on listing websites by providing visually appealing images that help buyers envision themselves living in the space."
    },
    {
      question: "Why should I pick virtual staging over physical staging?",
      answer: "The advantages of virtual staging when it comes to cost savings are quite clear. By eliminating the need to purchase furniture, hire decorators, move in decorations, and spend time decorating a house for staging, virtual staging can significantly reduce the costs when compared to traditional staging by up to 97%.\n\nMoreover, virtual staging options are more flexible than traditional methods. There are no limits to the styles of furniture that can be added, as virtual furniture can be sourced from anywhere and added to digital photos. This allows for a much wider range of furniture choices."
    },
    {
      question: "What is AI virtual staging?",
      answer: "AI virtual staging is when an artificial intelligence automatically adds furniture to a photo instead of a human designer. Through our proprietary machine learning models Virtual Staging AI can add beautiful, realistic looking furniture with natural lighting to any picture of a living or bedroom making AI virtual staging indistinguishable from human virtual staging or physical home staging.\n\nAt the same time AI virtual staging is a lot quicker than human virtual staging reducing the wait time from days to seconds and reducing the cost of virtual staging by magnitudes."
    },
    {
      question: "What kind of photos do you support?",
      answer: "We currently support staging living rooms, bedrooms, dining rooms, kitchens, home offices, and bathrooms and are adding more room types in the coming weeks. We offer eight different furniture styles to choose from. If the room already contains furniture or clutter we can declutter the image before adding new furniture and you will get the image of the empty room as well as the staged room."
    },
    {
      question: "How long does it take to virtually stage by AI?",
      answer: "It takes about 10 seconds to process the image and generate a virtually staged version."
    },
    {
      question: "How big is your furniture library?",
      answer: "Our AI virtual staging doesn't work with a predefined furniture library. It analyzes every room and based on the style and room types generates furniture that best matches the room. Therefore no two rooms will ever get the same furniture."
    }
  ];

  return (
    <section className="faq section" id="faqs">
      <div className="container">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div 
              key={index} 
              className={`faq-item ${activeIndex === index ? 'active' : ''}`}
              onClick={() => setActiveIndex(activeIndex === index ? null : index)}
            >
              <div className="faq-question">
                <h3>{faq.question}</h3>
                <IoIosArrowDown className="faq-icon" />
              </div>
              <div className="faq-answer">
                <p>
                  {faq.answer.split('\n\n').map((paragraph, i) => (
                    <span key={i}>
                      {paragraph}
                      {i < faq.answer.split('\n\n').length - 1 && (
                        <>
                          <br />
                          <br />
                        </>
                      )}
                    </span>
                  ))}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FAQ; 