import React from 'react';
import './Footer.css';
import logo from '../assets/logo.png';

function Footer() {
  return (
    <footer className="footer section">
      <div className="container">
        <div className="footer-content">
          <div className="footer-main">
            <img src={logo} alt="EasyInterior" className="footer-logo" />
            <div className="footer-links">
              <a href="#gallery">Gallery</a>
              <a href="#faqs">FAQs</a>
            </div>
          </div>
          <div className="footer-bottom">
            <p>Copyright © {new Date().getFullYear()} EasyInterior. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer; 