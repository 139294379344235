import React from 'react';
import './HowItWorks.css';
import step1 from '../assets/1.webp';
import step2 from '../assets/2.webp';
import step3 from '../assets/3.webp';

function HowItWorks() {
  const steps = [
    {
      icon: step1,
      title: "1. Upload Your Space",
      description: "Upload any empty room photo. We support all types of real estate spaces."
    },
    {
      icon: step2,
      title: "2. Choose Your Style",
      description: "Select from our curated collection of interior design styles and furniture."
    },
    {
      icon: step3,
      title: "3. Get Results",
      description: "Receive your professionally staged photos in minutes, not days."
    }
  ];

  return (
    <section className="how-it-works section">
      <div className="container">
        <h1>How It Works</h1>
        <div className="steps">
          {steps.map((step, index) => (
            <div key={index} className="step">
              <div className="image-container">
                <img src={step.icon} alt={step.title} className="step-icon" />
              </div>
              <div className="step-content">
                <h2>{step.title}</h2>
                <p>{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default HowItWorks; 