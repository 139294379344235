import React from 'react';
import './WhyVirtual.css';
import { BsGraphUp, BsFastForward, BsCurrencyDollar, BsEmojiSmile } from 'react-icons/bs';

function WhyVirtual() {
  const benefits = [
    {
      icon: <BsGraphUp />,
      title: "Increased buyer interest",
      description: "Virtual staging has a positive impact on 83% of buyers."
    },
    {
      icon: <BsFastForward />,
      title: "Sell faster",
      description: "Staged homes sell 73% faster than their non-staged counterparts."
    },
    {
      icon: <BsCurrencyDollar />,
      title: "Get higher offers",
      description: "Homes with virtual staging sell on average for 25% more"
    },
    {
      icon: <BsEmojiSmile />,
      title: "Stand out from the competition",
      description: "Convince more homeowners by using the latest technology."
    }
  ];

  return (
    <section className="why-virtual section">
      <div className="container">
        <h2>Why Virtually Stage?</h2>
        <div className="benefits-grid">
          {benefits.map((benefit, index) => (
            <div key={index} className="benefit-card">
              <div className="benefit-header">
                <div className="icon-wrapper">
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
              </div>
              <p>{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default WhyVirtual; 