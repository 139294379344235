import React, { useState } from 'react';
import './Navbar.css';
import logo from '../assets/logo.png';
import { FaBars } from 'react-icons/fa';

function Navbar({ openPopup }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setIsMenuOpen(false);
    }
  };

  return (
    <nav className="navbar">
      <div className="container navbar-content">
        <img src={logo} alt="EasyInterior" className="logo" />
        <div className="nav-group">
          <button className="menu-toggle" onClick={toggleMenu}>
            <FaBars />
          </button>
          <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
            <button onClick={() => scrollToSection('gallery')}>Gallery</button>
            <button onClick={() => scrollToSection('faqs')}>FAQs</button>
            <button className="waitlist-btn" onClick={openPopup}>Join Waitlist</button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar; 