import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import HowItWorks from './components/HowItWorks';
import Gallery from './components/Gallery';
import WhyVirtual from './components/WhyVirtual';
import FAQ from './components/FAQ';
import CTA from './components/CTA';
import Footer from './components/Footer';
import EmailPopup from './components/EmailPopup';
import './App.css';

function App() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  const handleEmailSubmit = (email) => {
    // Here you would typically send the email to your backend
    console.log('Email submitted:', email);
    closePopup();
  };

  return (
    <div className="app">
      <Navbar openPopup={openPopup} />
      <Hero openPopup={openPopup} />
      <HowItWorks />
      <Gallery />
      <WhyVirtual />
      <FAQ />
      <CTA openPopup={openPopup} />
      <Footer />
      <EmailPopup isOpen={isPopupOpen} onClose={closePopup} onSubmit={handleEmailSubmit} />
    </div>
  );
}

export default App; 