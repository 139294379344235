import React, { useState, useEffect } from 'react';
import './Gallery.css';

function Gallery() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
  const desktopImages = Array.from({ length: 12 }, (_, i) => ({
    src: require(`../assets/gallery/Gallery.${String(i + 1).padStart(3, '0')}.webp`),
    alt: `Gallery Image ${i + 1}`
  }));

  const mobileImages = Array.from({ length: 12 }, (_, i) => ({
    src: require(`../assets/gallery/Gallery Mobile.${String(i + 1).padStart(3, '0')}.webp`),
    alt: `Gallery Mobile Image ${i + 1}`
  }));

  const images = isMobile ? mobileImages : desktopImages;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    }, 3000);

    return () => clearInterval(timer);
  }, [images.length]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  return (
    <section className="gallery section" id="gallery">
      <div className="container">
        <h2>Before & After</h2>
        <div className="gallery-slider">
          <button className="slider-button prev" onClick={prevSlide}>&lt;</button>
          <div className="slide-container">
            <img 
              src={images[currentSlide].src} 
              alt={images[currentSlide].alt} 
              className="slide-image"
            />
          </div>
          <button className="slider-button next" onClick={nextSlide}>&gt;</button>
        </div>
        <div className="slide-dots">
          {images.map((_, index) => (
            <button
              key={index}
              className={`dot ${currentSlide === index ? 'active' : ''}`}
              onClick={() => handleSlideChange(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Gallery; 