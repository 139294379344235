import React from 'react';
import './Hero.css';
import heroImage from '../assets/hero-image.png';

function Hero({ openPopup }) {
  return (
    <section className="hero section">
      <div className="container hero-content">
        <div className="hero-text">
          <h1>Sell Your Property 73% Faster With AI</h1>
          <p>Use AI to virtually stage and design your real estate listings in minutes.</p>
          <button className="button button-primary" onClick={openPopup}>Join Waitlist Now</button>
        </div>
        <div className="hero-image">
          <img src={heroImage} alt="Interior Design Transformation" />
        </div>
      </div>
    </section>
  );
}

export default Hero; 