import React from 'react';
import './CTA.css';

function CTA({ openPopup }) {
  return (
    <section className="cta section">
      <div className="container">
        <div className="cta-content">
          <h2>Try easyInterior Now</h2>
          <button className="button button-contrast" onClick={openPopup}>Join Waitlist</button>
        </div>
      </div>
    </section>
  );
}

export default CTA; 